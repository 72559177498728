<template>
  <div class="home">
    <breadcrumb :items="breadcrumb" />

    <v-row justify="end">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Wyszukaj"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col style="text-align: right">
        <v-switch
          v-model="show_closed"
          @click="showSection()"
          inset
          :label="'Wyświetl: ' + (show_closed?'Zamknięte':'Aktywne')"
        ></v-switch>
      </v-col>
    </v-row>
    
    <v-data-table
      class="classic_table"
      :headers="headers"
      :items="list"
      :search="search"
      item-key="name"
      @click:row="issueClick"
      :footer-props="{
        itemsPerPageText : 'Elementów na stronie',
        itemsPerPageAllText : 'Wszystkie',
        itemsPerPageOptions : [15,45,90,-1],
      }"
    >
      <template v-slot:no-data>Brak danych</template>
      <template v-slot:no-results>Brak wyników</template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista zgłoszeń',
        disabled: true,
        to: '/zgloszenia',
      },
    ],

    headers: [
      { text: 'Numer zgłoszenia', value: 'id' },
      {
        text: 'Projekt',
        align: 'start',
        value: 'project_name',
      },
      { text: 'Tytuł', value: 'name' },
      { text: 'Piorytet', value: 'priority' },
    ],

    show_closed: false,

    list: [
      {
        id: '1',
        project_name: 'Xantro',
        description: 'Strona internetowa',
        piority: 1
      },
      {
        nr: '2',
        project_name: 'Xantro',
        description: 'Strona internetowa HOVER',
        piority: 2
      },
    ],

    search: ''
  }),
  methods: {
    issueClick(value) {
      // console.log(value.id);
      if(this.$store.state.user_permissions.issue_show)
        this.$router.push('/zgloszenie/zobacz/'+value.id);
    },
    showSection(){
      if(this.show_closed)
        this.getData(1)
      else 
        this.getData(0)
    },
    getData(type){
      this.$store.commit('loader');

      let url = '';

      // Pobieramy wszystkie
      if(type)
        url += '/issue_close';
      // Pobieramy tylko zamknięte
      else
        url += '/issue_all';

      this.$axios({url: this.$store.state.api + url, data: {}, method: 'GET' })
        .then(resp => {
          this.list = resp.data;
          console.log(resp.data);
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    this.getData(0);
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
  tr {
    cursor: pointer;
  }
}
</style>